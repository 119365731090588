import styles from './SplashPagePharmacyTopSection.module.scss'
import React, { ReactElement } from 'react'
import { reusableCssClass } from '@/utils/reusableCssClasses'
import { AuthenticatorRoutingProps } from '@/components/static/Authenticator/Authenticator.types'
import { SplashPageProps } from '@/components/sharedFetchStaticProps/fetchSplashPageProps'
import { ExcitedOwl } from '@/components/static/images/owlBranding/excitedOwl'
import { SplashPagePharmacyTitle } from '@/components/static/SplashPagePharmacy/SplashPagePharmacyTopSection/SplashPagePharmacyTitle/SplashPagePharmacyTitle'
import { SplashPagePharmacyValueProp } from '@/components/static/SplashPagePharmacy/SplashPagePharmacyTopSection/SplashPagePharmacyValueProp/SplashPagePharmacyValueProp'
import { SplashPageTopCTAButtons } from '@/components/static/SplashPageSharedComponents/SplashPageTopCTAButtons/SplashPageTopCTAButtons'
import { LinkItem } from '@/components/static/ui/LinkItem/LinkItem'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import { PharmacyStockImage } from '@/components/static/images/PharmacyStockImage'
import { SplashPageMiddleSection } from '@/components/static/SplashPage/SplashPageMiddleSection/SplashPageMiddleSection'
import { SplashPagePharmacyMiddleSection } from '@/components/static/SplashPagePharmacy/SplashPagePharmacyMiddleSection/SplashPagePharmacyMiddleSection'
import { SplashPagePharmacyButtonContainer } from '@/components/static/SplashPagePharmacy/SplashPagePharmacyButtonContainer/SplashPagePharmacyButtonContainer'

interface SplashPageTopSectionProps extends SplashPageProps {
    setIsLoginModalVisible: (isLoginModalVisible: boolean) => void
    setLoginModalRoutingPurpose: (
        loginModalRoutingPurpose: AuthenticatorRoutingProps
    ) => void
}

export const SplashPagePharmacyTopSection: React.FC<
    SplashPageTopSectionProps
> = (props): ReactElement => {
    return (
        <div className={styles.splashPageTopSectionContainer}>
            <div
                className={styles.splashPageTopSection}
                id={'cypress-splash-page'}
            >
                <div className={reusableCssClass.centerChildrenVertically}>
                    <PharmacyStockImage
                        priority={true}
                        className={styles.landingImage}
                    />
                </div>
                <div
                    className={`${styles.splashPageTopSectionContent} ${reusableCssClass.centerChildrenVertically}`}
                >
                    <SplashPagePharmacyTitle {...props} />
                    <div className={styles.splashPageValuePropContainer}>
                        <SplashPagePharmacyValueProp
                            title={
                                'Extensive Bank of AI-generated Questions & Notecards'
                            }
                            subtitle={
                                'Reviewed by our seasoned content creator'
                            }
                            icon={'star'}
                        />
                        <SplashPagePharmacyValueProp
                            title={'Unlimited Mock Exams'}
                            subtitle={'Practice as many times as you like'}
                            icon={'heart'}
                        />
                        <SplashPagePharmacyValueProp
                            title={'Personalized Progress Tracking'}
                            subtitle={
                                'Easily monitor progress and watch yourself grow'
                            }
                            icon={'shoppingBag'}
                        />
                    </div>
                    <div
                        className={styles.splashPageCTAButtonExternalContainer}
                    >
                        <SplashPagePharmacyButtonContainer
                            ctaButtonContainerClassName={
                                styles.pharmacyTopCTAButtonContainer
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

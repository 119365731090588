import React, { ReactElement } from 'react'
import { GetStaticProps } from 'next'
import { CourseConfigType } from 'common/src/courseConfig'
import {
    fetchSplashPageProps,
    SplashPageProps,
} from '@/components/sharedFetchStaticProps/fetchSplashPageProps'
import { SplashPagePharmacy } from '@/components/static/SplashPagePharmacy/SplashPagePharmacy'
import { NextSeoPharmacy } from '@/components/NextSeo/NextSeoPharmacy'
import { Layout } from 'antd'

export const getStaticProps = (async (_context) => {
    const response = await fetchSplashPageProps(
        CourseConfigType.pharmacyTechnician
    )
    return { props: response }
}) satisfies GetStaticProps<SplashPageProps>

// MUST be named SplashPageExport
const SplashPageExport = (props: SplashPageProps): ReactElement => {
    return <SplashPagePharmacy {...props} />
}

SplashPageExport.isStatic = true

SplashPageExport.getLayout = function getLayout(page: ReactElement) {
    return (
        <Layout>
            <NextSeoPharmacy />
            <>{page}</>
        </Layout>
    )
}

export default SplashPageExport

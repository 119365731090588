import React, { ReactElement } from 'react'
import Image from 'next/image'
import src from '../../../../public/splashPage/pharmacy/splashPageStock.webp'

export const PharmacyStockImage: React.FC<{
    priority: boolean
    className?: string
}> = (props): ReactElement => (
    <Image
        src={src}
        alt={'pharmacy cartoon stock image'}
        priority={props.priority}
        className={props.className}
    />
)

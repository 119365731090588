import { NextSeo } from 'next-seo'
import React, { ReactElement } from 'react'

export const NextSeoPharmacy: React.FC<unknown> = (): ReactElement => {
    return (
        <NextSeo
            title={'PassRxTech | Pharmacy Technician (PTCE) Exam Preparation'}
            description={
                'PassRxTech delivers carefully chosen resources aimed at students preparing for the Pharmacy Technician (PTCE) Exam. This encompasses detailed study guides, practice tests, and customized support, all designed to help students grasp the complex topics and methods necessary for acing the PTCE.'
            }
        />
    )
}

import React, { ReactElement, useEffect, useMemo, useState } from 'react'
import styles from './SplashPagePharmacy.module.scss'
import { useRouter } from 'next/router'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'
import { useRedditPixel } from '@/hooks/static/useRedditPixel'
import Modal from 'antd/lib/modal/Modal'
import authenticatorStyles from '@/components/static/Authenticator/Authenticator.module.scss'
import { Authenticator } from '@/components/static/Authenticator/Authenticator'
import {
    AuthenticatorRoutingProps,
    AuthenticatorTabName,
} from '@/components/static/Authenticator/Authenticator.types'
import { SplashPageProps } from '@/components/sharedFetchStaticProps/fetchSplashPageProps'
import { SplashPagePharmacyTopSection } from '@/components/static/SplashPagePharmacy/SplashPagePharmacyTopSection/SplashPagePharmacyTopSection'
import { SplashPagePharmacyMiddleSection } from '@/components/static/SplashPagePharmacy/SplashPagePharmacyMiddleSection/SplashPagePharmacyMiddleSection'

export const SplashPagePharmacy: React.FC<SplashPageProps> = (
    props
): ReactElement => {
    const router = useRouter()
    useEffect(() => {
        if (
            router &&
            router.asPath !== UiLayoutConstant.SPLASH_PAGE &&
            router.asPath !== UiLayoutConstant.SPLASH_PAGE_LOGGED_IN
        ) {
            router.replace({ pathname: UiLayoutConstant.SPLASH_PAGE })
        }
    }, [router])

    const [isLoginModalVisible, setIsLoginModalVisible] =
        useState<boolean>(false)
    const [loginModalRoutingPurpose, setLoginModalRoutingPurpose] =
        useState<AuthenticatorRoutingProps>(null)

    useRedditPixel()
    const authenticatorModal = useMemo((): ReactElement => {
        return (
            <Modal
                title={null}
                centered
                footer={null}
                open={isLoginModalVisible}
                onCancel={() => setIsLoginModalVisible(false)}
                className={authenticatorStyles.authenticatorModal}
            >
                <Authenticator
                    defaultTab={AuthenticatorTabName.signUp}
                    setIsModalVisible={setIsLoginModalVisible}
                    routingPurpose={loginModalRoutingPurpose}
                    courseConfigType={props.courseConfigType}
                />
            </Modal>
        )
    }, [isLoginModalVisible, loginModalRoutingPurpose, props.courseConfigType])
    return (
        <div className={styles.entirePageBackground}>
            {authenticatorModal}
            <div
                className={styles.splashPageBackground}
                id={'cypress-splash-page'}
            >
                <SplashPagePharmacyTopSection
                    {...props}
                    setIsLoginModalVisible={setIsLoginModalVisible}
                    setLoginModalRoutingPurpose={setLoginModalRoutingPurpose}
                />
                <SplashPagePharmacyMiddleSection />
            </div>
        </div>
    )
}

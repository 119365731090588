import styles from '../../SplashPageSharedComponents/SplashPageTopCTAButtons/SplashPageTopCTAButtons.module.scss'
import {
    CTAButton,
    CTAButtonType,
} from '@/components/static/ui/Button/CTAButton/CTAButton'
import React, { ReactElement } from 'react'
import Link from 'next/link'
import { UiLayoutConstant } from '@/globals/uiLayoutConstant'

export const SplashPagePharmacyButtonContainer: React.FC<{
    ctaButtonContainerClassName?: string
}> = (props): ReactElement => {
    return (
        <div
            className={`${styles.splashPageContainerCTAButtonContainer} ${
                props.ctaButtonContainerClassName ?? ''
            }`}
        >
            <div className={styles.splashPageContainerDemoButtonContainer}>
                <Link href={UiLayoutConstant.EDUCATOR}>
                    <CTAButton
                        type={CTAButtonType.primaryDarkBackground}
                        onClick={null}
                        text={'For Educators'}
                    />
                </Link>
            </div>
            <div className={styles.splashPageContainerBuyButtonContainer}>
                <Link href={UiLayoutConstant.COURSES_PAGE}>
                    <CTAButton
                        type={CTAButtonType.defaultBorderDarkBackground}
                        onClick={null}
                        text={'For Students'}
                    />
                </Link>
            </div>
        </div>
    )
}

import React, { ReactElement } from 'react'
import styles from './SplashPagePharmacyTitle.module.scss'
import { SplashPageProps } from '@/components/sharedFetchStaticProps/fetchSplashPageProps'
import { getCourseConfig } from 'common/src/courseConfig'

export const SplashPagePharmacyTitle: React.FC<SplashPageProps> = (
    props
): ReactElement => {
    const courseConfig = getCourseConfig(props.courseConfigType)
    return (
        <div className={styles.text}>
            <span className={styles.titleText}>
                <span className={styles.emphasis}>{courseConfig.name}</span>
            </span>
            <div className={styles.subtitleText}>
                Master the Pharmacy Technician exam with ease.
            </div>
        </div>
    )
}

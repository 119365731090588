import styles from './SplashPagePharmacyMiddleSection.module.scss'
import React, { ReactElement } from 'react'
import { LoomVideoPlayer } from '@/components/static/LoomVideoPlayer/LoomVideoPlayer'

export const SplashPagePharmacyMiddleSection: React.FC<
    unknown
> = (): ReactElement => {
    return (
        <div className={styles.splashPagePharmacyMiddleSection}>
            <LoomVideoPlayer
                src={
                    'https://www.loom.com/embed/ae86a5ea9895480889168ec69cb51f13?sid=96db4bc7-f3e4-458b-b4f6-4abdabaa9c89'
                }
                aspectRatio={1.79}
            />
        </div>
    )
}
